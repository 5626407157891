import router from '@manage/core/router'
import { slice } from '../utils'
import data from './data'
import { PayloadAction } from '@reduxjs/toolkit'
import storage from './storage'

type Data = typeof data
type Key = keyof Data
type Value<K extends Key> = Data[K]

type SetItem<K extends Key> = {
  /** 本地存储的key */
  key: Key
  /** 修改的值 */
  value: Value<K>
}
function initialState() {
  const state = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      state[key] = storage.getItem(key as Key)
    }
  }
  return state
}

const storageModel = slice({
  name: 'storage',
  initialState: initialState() as Data,
  reducers: {
    setState(state, { payload }) {
      Object.assign(state, payload)
    },
    setItem<K extends Key>(state, { payload }: PayloadAction<SetItem<K>>) {
      const { key, value } = payload
      state[key] = value
      storage.setItem(key, state[key])
    },
    /** 删除本地存储 */
    removeItem<K extends Key>(state, { payload }: PayloadAction<K>) {
      state[payload] = data[payload]
      storage.removeItem(payload)
    },
  },
})

export default storageModel
