/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-11 16:43:06
 * @Description:
 */

import { createContext } from 'react'

type AuthContext = {
  isLogin: boolean
  signIn?: () => Promise<void>
  signOut?: () => void
}
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
export const AuthContext = createContext<AuthContext>({
  isLogin: false
})
