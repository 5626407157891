/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-21 11:27:13
 * @Description:
 */

import { createSlice } from '@reduxjs/toolkit'
import router from '../../router'
import * as apis from './api'

const { reducer, actions, name, caseReducers } = createSlice({
  name: 'global',
  initialState: {
    // 请求接口时 token失效
    tokenFailure: false,
    isRoot: true,
    settings: {
      dark: false,
      primary: '#0E6CF0',
      borderRadius: 4,
      rtl: false,
    },
    collapsed: false,
    buttonList: [],
    menuCodeList: [],
    appEntry: (router.location.query as any)?.appEntry || '',
    user: {
      fsId: '',
      name: '',
      enName: '',
      avatarUrl: '',
      tenantKey: '',
      openId: '',
      unionId: '',
      userId: '',
      email: '',
      mobile: '',
    },
    shareInfoModal: {},
  },
  reducers: {
    setState(state, { payload }) {
      Object.assign(state, payload)
    },
    setSettings(state, { payload }) {
      return { ...state, settings: { ...state.settings, ...payload } }
    },
    setButtonList(state, { payload }) {
      return { ...state, buttonList: payload }
    },
    setMenuCodeList(state, { payload }) {
      return { ...state, menuCodeList: payload }
    },
    setIsRoot(state, { payload }) {
      return { ...state, isRoot: payload }
    },
  },
  extraReducers: {
    [apis.queryStaff.fulfilled.type]: (state, { payload }) => ({
      ...state,
      user: { ...state.user, ...payload },
    }),
  },
})

export default {
  name,
  reducer,
  caseReducers,
  actions: { ...actions, ...apis },
}
