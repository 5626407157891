/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-12 10:19:57
 * @Description:
 */
import { useEffect, useRef, useState } from 'react'
import History, { HistoryProps } from './history'

function useRouter<
  Q extends Record<string, any> = object,
  P extends Record<string, any> = object
>() {
  const [router, setRouter] = useState(History.history as HistoryProps<Q, P>)
  const listen = useRef<any>()


  useEffect(() => {
    listen.current = router.listen((location) => {
      setRouter(
        (r) =>
        ({
          ...r,
          location: History.transformLocation(location.location)
        } as HistoryProps<Q, P>)
      )
    })
    return () => {
      listen.current?.()
    }
  }, [])

  return router
}

export default useRouter
