import { theme } from 'antd'
import { css, cx } from '@emotion/css'
import styles from './css'

const { useToken } = theme
const CircleProgress = () => {
  const { token } = useToken()
  return (
    <div
      className={cx(
        'w-8 h-8 inline-block',
        css({
          color: token.colorPrimary,
          animation: `1.4s linear 0s infinite normal none running ${styles.animateRotate}`
        })
      )}
    >
      <svg viewBox="22 22 44 44">
        <circle
          className={cx(
            'stroke-current',
            css({
              strokeDasharray: '80px, 200px',
              strokeDashoffset: 0,
              animation: `${styles.animation} 1.4s ease-in-out infinite`
            })
          )}
          cx={44}
          cy={44}
          r={20.2}
          fill={'none'}
          strokeWidth="3.6"
        ></circle>
      </svg>
    </div>
  )
}

export default CircleProgress
