/*
 * @Date: 2022-11-01 11:54:39
 * @Description: 工具方法
 */

/** 动态创建js脚本插入页面 */
import { css } from '@emotion/css'
// @ts-ignore
import { TENANT } from "@/core/config"

export const createScript = (() => {
  const list: string[] = []
  return function a<T = any>(name: string, src: string): Promise<T> {
    return new Promise((resolve, reject) => {
      if (list.includes(name)) {
        resolve((window as any)[name] as unknown as T)
        return
      }

      const script = document.createElement('script')
      script.src = src
      document.body.appendChild(script)
      script.onload = () => {
        list.push(name)
        resolve((window as any)[name] as unknown as T)
      }
      script.onerror = (err) => {
        reject(err)
      }
    })
  }
})()
// 跳转前验证是否具备分享信息
export const isHaveShareInfo = async () => {
  const [data] = await $.fetch['POST/ums/internal/employeeExt/getShareInfo']({
    showErrorMessage: true,
    shouldTenantKey: true
  })
  return [!!(data as any).ypUserId, (data as any).userId]
}
// 一键登录
export const oneClickLogin = async () => {
  const testUrl = 'GET/ums/internal/employee/oauth/url'
  // 请求后端接口获取
  const [data]= await $.fetch[testUrl]({
    goUrl: `${window.location.origin}/`
  }, {
    headers: { business: TENANT }
  })
  window.location.href = data.oauthUrl
}
export const loginMainInteractive = css`
  float: left;
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
`
export const loginMain = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 560px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 52px 48px -40px rgba(117, 139, 160, 0.7);
`
export const loginMainImg = css`
  float: left;
  border-radius: 8px 0 0 8px;
  width: 50%;
  height: 100%;
  background-color: rgba(117, 139, 160, 1);
`
export const loginMainImgText = css`
  width: 100%;
  height: 30%;
  padding: 36px 30px;
`

export const h1Css = css`
  font-size: 29px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 38.45px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
`
export const h2Css = css`
  font-size: 29px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 38.45px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
`
/** 是否cookie冲突 */
/** test/dev/(master/prod)三方冲突 */
export const areUrlsEnvironmentNonConflicting = (url1?: string, url2?: string) => {

  const envPattern = /(test|dev|master)/i // 匹配URL中的环境标识
  const match1 = url1?.match(envPattern)?.[0]?.toLowerCase()
  const match2 = url2?.match(envPattern)?.[0]?.toLowerCase()

  // 正式站与master不冲突
  if ((match1 === 'master' && !match2 ) || (!match1  && match2 === 'master')) {
    return true
  }
  if (!match1 || !match2) {
    return true
  }
  return match1 === match2
}
