import { Fragment, useCallback, useState } from 'react'
import { Menu, theme } from 'antd'

import useRouter from '@manage/core/router/useRouter'
import { MenuItemType, menus } from '@/pages'
import { useSelector } from '@/store'
import renderStyles from './css'

function findParentKeys(data: MenuItemType[], key: string, parents: string[] = []): string[] {
  for (const cur of data) {
    if (cur.key === key) {
      return parents.length ? parents : []
    }
    if (cur?.children && cur.children.length) {
      const keys = findParentKeys(cur.children, key, parents.concat(cur.key))
      if (keys.length) {
        return keys
      }
    }
  }
  return []
}

const { useToken } = theme

const CustomMenu = () => {
  const router = useRouter()
  const { dark } = useSelector(state => state.global.settings)
  const { pathname } = router.location
  const { collapsed, appEntry: selectedEntry } = useSelector(state => state.global)
  const menuCodeList = useSelector(state => state.global.menuCodeList)
  const isRoot = useSelector(state => state.global.isRoot)
  const filterMenus = menus
  const appEntry = selectedEntry || /^\/[a-z]+/.exec(pathname)?.[0]?.replace('/', '') || ''
  const { token } = useToken()

  const styles = renderStyles({ dark, token, collapsed })
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const items = filterMenus
    .find(({ path }) => new RegExp(`/${appEntry}`).exec(path || ''))
    ?.children?.filter(i => !i.hidden) || []

  const handleSelect = (key: string) => {
    router.push(key)
  }

  const onload = useCallback((container: HTMLDivElement) => {
    if (container) {
      const keys = findParentKeys(items, router.location.pathname)
      // 设置100ms的时间间隔 是为了防止在收缩切换sider时，menu样式闪烁
      setTimeout(() => {
        setOpenKeys(keys)
      }, 100)
    }
  }, [])

  return (
    <Fragment>
      <div ref={onload} />
      <Menu
        selectedKeys={[router.location.pathname]}
        openKeys={collapsed ? undefined : openKeys}
        className={styles['ant-menu']}
        items={items}
        mode="inline"
        theme="dark"
        onSelect={({ key }) => handleSelect(key)}
        onOpenChange={keys => setOpenKeys(keys)}
      />
    </Fragment>
  )
}

export default CustomMenu
