import { Button, Result } from 'antd'
import React from 'react'
import dayjs from 'dayjs'

// @ts-ignore
import { SYSTEM_NAME, MODE } from '@/core/config'

// @ts-ignore
import { store } from '@/store'
import StackTrace, { StackFrame } from 'stacktrace-js'

const lastError = {
  name: '',
  time: '',
}
const getSourceCodeFromErrorPosition = async (
  componentStack: string,
  fileNameMap: Record<string, { line: number; column: number }>
) => {
  // @ts-ignore
  const urlList = Array.from(
    componentStack.matchAll('(http.*?\\.js):([0-9]+):([0-9]+)')
  ) as string[][]
  const result = {
    source: '',
    specificPosition: '',
  }

  let lastFilePath = ''
  let consumer: any
  const loop = async (index: number) => {
    const [, filePath, lineNumber, columnNumber] = urlList[index]
    if (filePath !== lastFilePath) {
      const rawSourceMap = await fetch(
        `${filePath.replace(/\.js$/, '.js.map')}?${Date.now()}`
      ).then((res) => res.json())
      consumer?.destroy()
      consumer = await new window.sourceMap.SourceMapConsumer(rawSourceMap)
    }
    lastFilePath = filePath
    const originalPosition = consumer.originalPositionFor({
      line: +lineNumber,
      column: +columnNumber,
    })

    if (
      (originalPosition.source?.includes('/node_modules/') ||
        !fileNameMap[originalPosition.source]) &&
      index !== urlList.length - 1
    ) {
      await loop(index + 1)
      return
    }
    const sourceIndex = consumer.sources.findIndex(
      (item: string) => item === originalPosition.source
    )
    const sourceContent = consumer?.sourcesContent[sourceIndex]
    const contentRowArr = sourceContent?.split('\n')
    const positionInfo = fileNameMap[originalPosition.source] || originalPosition
    result.source = `${originalPosition.source} ${positionInfo.line}:${positionInfo.column}`
    result.specificPosition =
      contentRowArr?.[(fileNameMap[originalPosition.source]?.line || originalPosition.line) - 1]

    consumer?.destroy()
  }

  await loop(0)

  return result
}

// 环境
const envName = {
  develop: '开发站',
  test: '测试站',
  master: '预发布',
  prod: '正式站',
}
export default class ErrorBoundary extends React.Component<{ children: React.ReactElement }> {
  state = {
    hasError: false,
  }

  constructor(props: { children: React.ReactElement }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true }
  }

  async componentDidCatch(error: any, errorInfo: any) {
    if (import.meta.env?.DEV) {
      return
    }
    // 你同样可以将错误日志上报给服务器
    // logErrorToMyService(error, errorInfo)
    // https://open.feishu.cn/open-apis/bot/v2/hook/8e83c1cc-8035-4325-9265-a37c8d25bc2a
    const time = dayjs().format('YYYY-MM-DD HH:mm:ss')
    // 防止重复报同一时间同一个错误
    if (error?.message === lastError.name && time === lastError.time) {
      return
    }
    lastError.time = time
    lastError.name = error?.message
    const fileNameMap: Record<string, { line: number; column: number }> = {}
    const stackFrames = await StackTrace.fromError(error, {
      filter: (stackFrame: StackFrame) => !stackFrame?.fileName?.includes('/node_modules/'),
      // @ts-ignore
      ajax: (url) => fetch(`${url}?${Date.now()}`).then((res) => res.text()),
    })
    stackFrames.forEach((item) => {
      const { fileName, lineNumber = 0, columnNumber = 0 } = item
      if (fileName) {
        fileNameMap[fileName] = { line: lineNumber, column: columnNumber }
      }
    })
    const userName =
      store.getState().global?.user?.name || store.getState()?.globalModel?.user?.name
    const data = await getSourceCodeFromErrorPosition(errorInfo.componentStack, fileNameMap)
    if (MODE !== 'develop') {
      fetch('https://open.feishu.cn/open-apis/bot/v2/hook/51cfd458-bb29-4a7e-b6c7-49380f383aac', {
        method: 'POST',
        body: JSON.stringify({
          msg_type: 'interactive',
          card: {
            elements: [
              {
                tag: 'markdown',
                content: `报错信息：\n ${error}`,
              },
              {
                tag: 'markdown',
                content: `报错文件名: \n ${data.source}`,
              },
              {
                tag: 'markdown',
                content: `报错索引代码：\n ${data.specificPosition}`,
              },
              {
                tag: 'column_set',
                flex_mode: 'none',
                background_style: 'grey',
                columns: [
                  {
                    tag: 'column',
                    width: 'weighted',
                    weight: 1,
                    vertical_align: 'top',
                    elements: [
                      {
                        tag: 'column_set',
                        flex_mode: 'none',
                        background_style: 'default',
                        columns: [
                          {
                            tag: 'column',
                            width: 'weighted',
                            weight: 1,
                            vertical_align: 'top',
                            elements: [
                              {
                                tag: 'markdown',
                                content: `**平台：**\n<font color='red'>${SYSTEM_NAME}</font>`,
                              },
                            ],
                          },
                          {
                            tag: 'column',
                            width: 'weighted',
                            weight: 1,
                            vertical_align: 'top',
                            elements: [
                              {
                                tag: 'markdown',
                                content: `**用户名：**\n${userName || '用户未登录'}`,
                              },
                            ],
                          },
                          {
                            tag: 'column',
                            width: 'weighted',
                            weight: 1,
                            vertical_align: 'top',
                            elements: [
                              {
                                tag: 'markdown',
                                content: `**环境：**\n${envName[MODE]}`,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        tag: 'column_set',
                        flex_mode: 'none',
                        background_style: 'default',
                        columns: [],
                      },
                      {
                        tag: 'column_set',
                        flex_mode: 'none',
                        background_style: 'default',
                        columns: [
                          {
                            tag: 'column',
                            width: 'weighted',
                            weight: 1,
                            vertical_align: 'top',
                            elements: [
                              {
                                tag: 'markdown',
                                content: `**时间：**\n${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
                              },
                            ],
                          },
                          {
                            tag: 'column',
                            width: 'weighted',
                            weight: 1,
                            vertical_align: 'top',
                            elements: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            header: {
              template: 'wathet',
              title: {
                content: '中台子系统白屏通知',
                tag: 'plain_text',
              },
            },
          },
        }),
      })
    }
  }

  render() {
    if (this.state.hasError) {
      // 自定义降级后的 UI 并渲染
      return (
        <div className="flex justify-center items-center h-[100vh]">
          <Result
            status="404"
            subTitle="抱歉，页面迷路了！返回首页让我重新出发吧！"
            extra={
              <Button type="primary" onClick={() => location.replace('/')}>
                返回首页
              </Button>
            }
          />
        </div>
      )
    }

    return this.props.children
  }
}
