import { useEffect } from "react"
import { Watermark } from 'watermark-js-plus'

const useWatermark = (content: string) => {

  useEffect(() => {
    const watermark = new Watermark({
      content,
      width: 200,
      height: 200,
      rotate: 25,
      fontColor: 'rgba(150, 150, 150, 0.3)',
      fontSize: '14px',
      onSuccess: () => {
        // success callback
      }
    })
    watermark.create()
    return (() => watermark.destroy())
  }, [content])
}
export default useWatermark
