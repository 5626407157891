import { css, cx } from '@emotion/css'
import { AliasToken } from 'antd/es/theme/internal'

const renderStyles = (args: { token: Partial<AliasToken>; dark: boolean; collapsed: boolean }) => {
  const { token } = args
  return {
    'ant-menu': css({
      backgroundColor: 'transparent',
      '.ant-menu-submenu-inline .ant-menu-submenu-arrow::before': {
        transform: 'rotate(45deg) translateX(-2.5px) !important'
      },
      '.ant-menu-submenu-inline .ant-menu-submenu-arrow::after': {
        transform: 'rotate(-45deg) translateX(-2.5px) !important'
      },
      '.ant-menu-submenu-open .ant-menu-submenu-arrow::before': {
        transform: 'rotate(-45deg) translate(1px, 1.5px) !important'
      },
      '.ant-menu-submenu-open .ant-menu-submenu-arrow::after': {
        transform: 'rotate(-135deg) translate(1px, -1.5px) !important'
      }
    }),
    'menu-list': cx(
      css({
        listStyle: 'none'
      })
    ),
    'menu-item': cx(
      'ant-menu-item ant-menu-item-only-child',
      css({
        lineHeight: '48px',
        minHeight: 48,
        overflow: 'hidden',
        borderRadius: token.borderRadius,
        background: '#001529',
        padding: '0',
        color: token.colorWhite,
        transitionDuration: '.2s',
        transitionTimingFunction: 'ease-in',
        marginBlock: 4,
        cursor: 'pointer',
        ':not(.sub-menu)': {
          cursor: 'pointer'
        },
        '&:hover': {
          background: 'rgba(42, 52, 68, 1)',
          color: `${token.colorWhite} !important`,
          ':not(.sub-menu)': {
            // background: '#1677ff64'
          }
        }
      })
    ),
    'menu-item-active': css({
      background: '#001529',
      color: token.colorWhite,
      lineHeight: '48px',
      minHeight: 48,
      '&:hover': {
        color: `${token.colorWhite} !important`,
        background: '#001529 !important'
      },
      ':not(.sub-menu)': {
        color: `${token.colorWhite} !important`,
        background: '#1677ff !important',
        '&:hover': {
          background: '#1677ff !important'
        }
      }
    }),
    'menu-item-title': cx(
      'ant-menu-title-content',
      css({
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 0
      })
    ),
    'menu-item-collapsed': cx(
      '"ant-menu-inline-collapsed-noicon',
      css({
        textAlign: 'center'
      })
    ),
    'menu-item-content': cx(
      'flex flex-row flex-1 justify-between',
      css({
        height: 48,
        padding: '0 24px'
      })
    ),
    'card-container': css({
      borderRadius: token.borderRadius,
      listStyle: 'none',
      transition: 'all 300ms ease-in-out',
      background: '#000d17',
    }),
    'card-title': cx(
      'flex flex-row flex-1 justify-between items-center',
      css({
        padding: '0 24px'
      })
    ),
    'card-title-content': css({
      color: token.colorWhite,
      fontSize: 14,
      fontWeight: 500
    }),
    'card-title-extra': css({
      fontSize: 12,
      fontWeight: 400
    }),
    tag: cx(
      'flex flex-row justify-between items-center',
      css({
        marginRight: 0,
        borderRadius: token.borderRadius,
        padding: '0 6px',
        lineHeight: '21px',
        fontSize: 12,
        width: 54
      })
    ),
    'card-body': cx('flex flex-col'),
    'card-line-container': cx(
      'flex flex-row justify-between items-center',
      css({
        '*': {
          cursor: 'pointer'
        },
        padding: '0 22px',
        marginInline: 4,
        borderRadius: token.borderRadius,
        marginBlock: 2,
        background: '#000d17',
        color: '#ffffff65',
        transitionDuration: '220ms',
        transitionTimingFunction: 'ease-in',
        cursor: 'pointer',
        height: '38px',
        '&:hover': css({
          background: 'rgba(42, 52, 68, 1)',
          color: token.colorWhite
        })
      })
    ),
    'card-line-container-active': css({
      background: '#1677ff',
      color: token.colorWhite,
      cursor: 'pointer',
      '&:hover': css({
        background: '#1677ff',
        color: token.colorWhite
      })
    }),
    'card-footer': cx(css({})),
    icon: css({
      transitionDuration: '300ms',
      transitionTimingFunction: 'linear',
      // display: collapsed ? 'none' : 'flex'
    }),
    'card-body-divider': css({
      margin: 16,
      minWidth: 'auto',
      width: 'auto',
      borderColor: '#dfe4eb45'
    }),
    'arrow-up': css({
      transform: 'rotate(90deg)'
    })
  }
}

export default renderStyles
