import { keyframes } from '@emotion/react'

const styles = {
  animation: keyframes({
    '0%': {
      strokeDasharray: '1px, 200px',
      strokeDashoffset: 0
    },
    '50%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-15px'
    },
    '100%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-125px'
    }
  }),
  animateRotate: keyframes({
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  })
}

export default styles
