/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-11 17:03:04
 * @Description: 导出store
 */

import global from './global'


export default {
  actions: {
    global: global.actions,
  },
  reducer: {
    [global.name]: global.reducer,
  }
}
