// import { configureStore } from '@reduxjs/toolkit'
// import { connect as oldConnect, useDispatch, useSelector as oldUseSelector } from 'react-redux'
// import coreGlobal from '@manage/core/store'
// import { actions as oldActions, reducer, actions as selfActions } from '@/store/reducer'

// export const actions = {
//   ...coreGlobal.actions,
//   ...selfActions
// }

// export const store = configureStore({
//   devTools: false,
//   reducer: { ...reducer, ...coreGlobal.reducer },
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
//     serializableCheck: false,
//   }),
// })

// export const { dispatch } = store
// export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch
// export const useAppDispatch = () => useDispatch<AppDispatch>()

// type Connect = (
//   mapStateToProps: (state: RootState) => Record<string, any>,
//   mapDispatchToProps?: (
//     dispatch: AppDispatch,
//     actions: typeof oldActions,
//   ) => Record<string, any>,
// ) => Function

// /** connect 内置了类型提示 */
// export const connect: Connect = oldConnect

// type EqualityFn<T> = (a: T, b: T) => boolean
// type UseSelector = <T>(
//   select: (s: RootState) => T,
//   equalityFn?: EqualityFn<T>,
// ) => T

// /** useSelector 内置了类型提示 */
// export const useSelector: UseSelector = oldUseSelector

import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector as oldUseSelector, TypedUseSelectorHook } from 'react-redux'
import coreGlobal from '@manage/core/store'

import { INIT_STORE } from './utils'
import { storageMiddleware } from './storageMiddleware'
import globalModel from './globalStore'
import storageModel from './storage'

const store = configureStore({
  reducer: {
    [globalModel.name]: globalModel.reducer,
    [storageModel.name]: storageModel.reducer,

    global: coreGlobal.reducer.global
  },
  middleware: (getDefault) => getDefault().concat(
      storageMiddleware({
        persistTrees: [{ key: 'globalStore.settings', type: 'local' }]
      })
    )
})

export const actions = {
  [globalModel.name]: globalModel.actions,
  [storageModel.name]: storageModel.actions,
  global: coreGlobal.actions.global
}
store.dispatch({ type: INIT_STORE, payload: {} })

export default store
export { store }

export type AppState = ReturnType<AppStore['getState']>
export type AppStore = typeof store
export type AppDispatch = AppStore['dispatch']
export type RootState = ReturnType<typeof store.getState>
type EqualityFn<T> = (a: T, b: T) => boolean

type UseSelector = <T>(select: (s: RootState) => T, equalityFn?: EqualityFn<T>) => T

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = oldUseSelector
export const useSelector: UseSelector = oldUseSelector
export const { dispatch } = store
