import { css } from '@emotion/css'

const renderStyles = () =>
// css
({
  page: css({
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }),
  leftPage: css({
    width: '50%',
    height: '100%',
    // backgroundColor: 'red',
  }),
  rightPage: css({
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    height: '100%',
  }),
  card: css({
    width: '500px',
    height: '560px',
    gap: '42px',
    flexShrink: 0,
    padding: '53px 40px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 0px 20px rgba(163, 202, 231, 0.5)',
  }),
  titleLogo: css({
    marginTop: '34px',
    marginLeft: '34px',
    height: '60px',
    display: 'inline-flex',
    placecontent: 'flex-start',
    placeItems: 'center',
    gap: '10px',
  }),
  logo: css({
    height: '60px',
    width: '60px',
  }),
  title: css({
    width: '326px',
    height: '44px',
    color: 'rgba(51, 51, 51, 1)',
    fontFamily: 'Alibaba PuHuiTi',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '44px',
  }),
  change: css({
    // display: 'flex',
    // justifyContent: 'flex-end',
    // marginBottom: '10px',
    fontWeight: '600',
    fontSize: '20px',
    width: '420px',
    height: 'fit-content',
    display: 'inline-flex',
    placeContent: 'center',
    placeItems: 'center',
    gap: '32px',
    flexShrink: 0,
    marginBottom: '25px',
  }),
  titleIn: css({
    width: '1px',
    height: '18px',
    borderRadius: '2px',
    background: ' rgba(232, 232, 232, 1)',
  }),
  footerButton: css({
    background: 'rgba(24, 130, 255, 1)',
    width: '420px',
    height: '54px',
    minWidth: '60px',
    gap: '10px',
    flexShrink: 0,
    padding: '5px 12px',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: ' PingFang SC',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '22px',
  }),
  version: css({
    position: 'absolute',
    bottom: '34px',
    right: '34px',
    padding: '14px 20px',
    borderRadius: '100px',
    border: '2px solid rgba(54, 103, 144, 1)',
  }),
  versionFont: css({
    width: '85px',
    height: '22px',
    color: 'rgba(54, 103, 144, 1)',
    fontFamily: 'PingFang SC',
    fontWeight: '600',
    fontSize: ' 20px',
    lineHeight: '22px',
  }),
  loginInput: css({
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: '0.5px solid rgba(232, 232, 232, 1)',
  }),
  recordNumber: css({
    color: 'black',
    position: 'absolute',
    top: '98%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  })
})

export default renderStyles
