import { Middleware } from '@reduxjs/toolkit'
import isEqual from 'fast-deep-equal'
import { produce } from 'immer'
import get from 'lodash/get'
import set from 'lodash/set'
import {
  getCookie,
  getLocalStore,
  getSessionStore,
  setCookie,
  setLocalStore,
  setSessionStore
} from '@manage/core/tools/storage'
import { INIT_STORE } from './utils'

type IPersistTree = {
  key: string
  type: 'local' | 'session' | 'cookie' | string
}
type IStorageConfig = {
  persistTrees: IPersistTree[]
}
export const transformState = (
  persistTrees: IPersistTree[],
  transform: (persistTree: IPersistTree, currentState: any, newState: any) => any,
  state: any,
  newState?: any
) => produce(state, (draftState: any) => {
  for (const persistTree of persistTrees) {
    const result = transform(
      persistTree,
      get(state, persistTree.key),
      get(newState, persistTree.key)
    )
    if (typeof result !== 'undefined') {
      set(draftState, persistTree.key, result)
    }
  }
})

export function storageMiddleware(config: IStorageConfig): Middleware {
  return (store) => (next) => (action) => {
    if (action.type === INIT_STORE) {
      if (config?.persistTrees?.length) {
        action.payload = transformState(
          config.persistTrees,
          ({ key, type }) => {
            if (type === 'local') {
              return getLocalStore(key)
            } if (type === 'session') {
              return getSessionStore(key)
            }
            return getCookie(key)
          },
          action.payload
        )
      }
    }
    {
      const oldState = store.getState()
      const result = next(action)
      const newState = store.getState()
      if (config?.persistTrees?.length) {
        transformState(
          config?.persistTrees,
          ({ key, type }, oldSubtreeState, newSubtreeState) => {
            if (!isEqual(oldSubtreeState, newSubtreeState)) {
              if (type === 'local') {
                setLocalStore(key, newSubtreeState)
              } else if (type === 'session') {
                setSessionStore(key, newSubtreeState)
              } else {
                setCookie(key, newSubtreeState)
              }
            }
          },
          oldState,
          newState
        )
      }
      return result
    }
  }
}
