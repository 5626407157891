
import router from '@manage/core/router'
import { slice } from '../utils'
// import * as apis from './api'

const globalModel = slice({
  name: 'globalModel',
  initialState: <any>{
    userInfo: {},
    isLogin: false,
    tableTaskId: '',
    collapsed: false,
    settings: {
      dark: false,
      primary: '#0E6CF0',
      borderRadius: 4,
      rtl: false
    },
    appEntry: (router.location.query as any)?.appEntry || '',
  },
  reducers: {
    setState(state, { payload }) {
      Object.assign(state, payload)
    },
    setUserInfo(state, { payload }: any) {
      return { ...state, userInfo: payload } as any
    },
    setIsLogin(state, { payload }: any) {
      return { ...state, isLogin: payload } as any
    },

    setTableTaskId(state, { payload }: any) {
      return { ...state, tableTaskId: payload } as any
    }
  },

})

export default globalModel
