/*
 * @Date: 2022-11-02 09:25:22
 * @Description: 本地存储工具方法
 */

import data from './data'

type Key = keyof typeof data
type Value<K extends Key> = typeof data[K]

const storage = {
  getItem<K extends Key>(key: K): Value<K> {
    const value = window.localStorage.getItem(key)
    try {
      const result = JSON.parse(value)
      return result ?? data[key]
    } catch (e) {
      return data[key]
    }
  },
  setItem<K extends Key>(key: K, value: Value<K>) {
    return window.localStorage.setItem(key, JSON.stringify(value))
  },
  removeItem<K extends Key>(key: K) {
    return window.localStorage.removeItem(key)
  },
  clear() {
    return window.localStorage.clear()
  },
}

export default storage
