/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-25 11:41:13
 * @Description: async thunks
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
// @ts-ignore
import { BUSINESS } from '@/core/config'

enum BizCode {
  css = 'CUSTOMER_SERVICE',
  scrm = 'OUTBOUND',
  qms = 'QUALITY_INSPECTION',
  mms = 'MARKETING_SERVICE',
  ams = 'AMS'
}

enum TENANT_ID {
  css = 3,
  scrm = 2,
  qms = 4,
  mms = 5,
  ams = 6
}

/**
 * API 获取专员信息 业务阐述写死
 * 员工号使用飞书ID
 */
export const queryStaff = createAsyncThunk<IModels['POST/scrm/v1/staff/info']['Res']>(
  'global/queryStaff',
  async (_, { rejectWithValue }) => {
    return $.fetch['POST/scrm/v1/staff/info']({
      bizCode: BizCode[BUSINESS as keyof typeof BizCode],
      tenantId: TENANT_ID[BUSINESS]
    } as any)
      .then(([data]) => data)
      .catch(rejectWithValue)
  }

)