/*
 * @Date: 2022-05-28 13:42:03
 * @Description: 本地缓存数据默认数据
 */

export default {
  /** 主题色 */
  theme: '#1890ff',
  /** 色弱模式 */
  blindnessMode: '0',
  /** table设置 */
  tableSetting: {
    /** table 大小 */
    size: 'middle',
  },
  /** 登录后重定向的页面 */
  goUrl: '',
  /** 用户信息 */
  userInfo: {} as {
    avatarUrl: string
    email: string
    enName: string
    mobile: string
    name: string
    openId: string
    tenantKey: string
    unionId: string
    userId: string
    userName: string
    channelCode: number
    loginName: string
    dataEnc: string
  },
  /** 权限和菜单信息 */
  permissionInfo: {
    /** 权限码 */
    permissions: [] as Array<any>,
    /** 是不是超级管理员 */
    superAdmin: false,
    /** 全部菜单菜单列表 */
    menuList: [] as Array<any>,
  },
  /** 历史搜索菜单path，缓存5个 */
  searchMenus: [] as string[],
}
