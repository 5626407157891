

import { css } from '@emotion/css'

const globalStyles = css`

  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }
`

export default globalStyles
