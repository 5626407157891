/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-23 09:56:34
 * @Description:
 */
import { Layout, theme } from 'antd'
import { useLayoutEffect, useState } from 'react'

import renderStyles from './css'

import { actions, useAppDispatch, useSelector } from '@/store'
import CustomMenu from '../CustomMenu'
import { CDN_PREFIX, SYSTEM_LOGO, SYSTEM_NAME } from '@/core/config'

const { Sider: AntSider } = Layout
const { useToken } = theme

const logoIcon = `${CDN_PREFIX}assets/logo.png`
const Sider = () => {
  const { token } = useToken()
  const collapsed = useSelector((state) => state.global.collapsed)
  const { dark } = useSelector((state) => state.global.settings)
  const [breakpoint, setBreakPoint] = useState(true)
  const dispatch = useAppDispatch()
  const styles = renderStyles({ token, dark })

  const handleChangeCollapsed = (value: boolean) => {
    dispatch(actions.global.setState({ collapsed: value }))
  }

  // 断点时 设置一次 collapsed
  useLayoutEffect(() => {
    if (breakpoint) {
      dispatch(actions.global.setState({ collapsed: true }))
    }
  }, [breakpoint, dispatch])

  const renderLogo = () => (
    <>
      {!collapsed || breakpoint ? (
        <div className={styles.siderLogo}>
          {/* <img src={logoIcon} className={styles.siderIcon} /> */}
          <div className={styles.siderLogoIn}>
            <img src={SYSTEM_LOGO} className={styles.siderIcon} />
            <div className={styles.siderContent}>
              <div className={styles.siderLogoText}>{SYSTEM_NAME}</div>
              <div className={styles.company}></div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.siderLogo}>
          <img src={logoIcon} className={styles.siderIcon} />
        </div>
      )}
    </>
  )

  const renderMenu = () => (
    <div className={styles.menu}>
      <CustomMenu />
    </div>
  )

  return (
    <AntSider
      className={styles.sider}
      theme="light"
      width={256}
      collapsedWidth="72"
      breakpoint="lg"
      collapsed={false}
      onBreakpoint={(value) => {
        setBreakPoint(value)
        handleChangeCollapsed(value)
      }}
    >
      {renderLogo()}
      {renderMenu()}
    </AntSider>
  )
}

export default Sider
