

export const filterEmpty = (source: Record<string, any>) => {
  if (typeof source !== 'object') {
    return source
  }
  if (Array.isArray(source)) {
    return source
  }
  const result = { ...source }
  Object.keys(result).forEach(key => {
    if (
      !Object.prototype.hasOwnProperty.call(result, key) ||
      result[key] === '' ||
      result[key] === undefined ||
      result[key] === null
    ) {
      delete result[key]
    }
  })
  return result
}
export const delEmptyOp = (source:Record<string,any>)=> {
  if (typeof source !== 'object') {
    return source
  }
  const target = { ...source }
  Object.keys(target).forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(target, key) || target[key] === '' || target[key] === null) {
      delete target[key]
    }
  })
  return target
}