/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-23 09:56:34
 * @Description:
 */
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { HomeFilled, HomeOutlined, RightOutlined } from '@ant-design/icons'
import { css, cx } from '@emotion/css'
import useRouter from '@manage/core/router/useRouter'
import { matchPath } from 'react-router-dom'
import { menus } from '@/pages'

function findParents(data: typeof menus, key: string, parents: typeof menus = []): typeof menus {
  for (const cur of data) {
    if (matchPath(cur.path, key)) {
      return parents.concat(cur)
    }
    if (cur?.children && cur.children.length) {
      const items = findParents(cur.children, key, parents.concat(cur))
      if (items.length) {
        return items
      }
    }
  }
  return []
}

const Breadcrumb = () => {
  const router = useRouter()

  const { pathname } = router.location

  const dataSource = findParents(menus, pathname)

  return (
    <AntBreadcrumb
      separator={<RightOutlined />}
      // className={css({ marginBlock: '0px 18px' })}
      items={[
        {
          title: (
            <div className="cursor-pointer">
              {/* <HomeFilled /> */}
              <div style={{ width: 20, height: 20 }}>
                <HomeOutlined />
              </div>
            </div>
          )
        }
      ].concat(
        dataSource.filter(i => i.name).map((item) => ({
          title: <span className={cx('font-medium text-sm')}>{item.name}</span>
        }))
      )}
    ></AntBreadcrumb>
  )
}

export default Breadcrumb
