import Big from 'big.js'


export const plus = (a:number|string,b:number|string) => {
  return Big(a).plus(Big(b)).toNumber()
}

export const minus = (a:number|string,b:number|string) => {
  return Big(a).minus(Big(b)).toNumber()
}
export const times = (a:number|string,b:number|string) => {
  return Big(a).times(Big(b)).toNumber()
}
export const div = (a:number|string,b:number|string) => {
  return Big(a).div(Big(b)).toNumber()
}
