/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-18 13:46:55
 * @Description:
 */
import React, { useEffect, useRef } from 'react'
import { remove } from 'lodash'
import { message } from 'antd'
import Visible from '@manage/core/component/Visible'
import { useLoadingHandle } from '@manage/core/hooks/use-loading-handle'
import { getCookie, setCookie } from '@manage/core/tools/storage'
import DefaultLoginPage from './DefaultPage'
import { AuthContext } from './context'
// 反向引用
// @ts-ignore
import { API_PREFIX, BUSINESS, SYSTEM_LOGO, SYSTEM_NAME } from '@/core/config'
// @ts-ignore
import { actions, dispatch, useSelector } from '@/store'
import Skeleton from './Skeleton'
import { areUrlsEnvironmentNonConflicting } from './utils/utils'
import { extractCodes } from '../tools'

type Props = {
  children: React.ReactNode
  bgUrl?: string
  slogan?: React.ReactElement
  logo?: string
}

function Auth(props: Props) {
  const { children, bgUrl, slogan, logo } = props
  const form = useRef<HTMLFormElement | null>(null)
  const isLogin = useSelector(
    (state) => state.globalModel.isLogin)
  const hasToken = !!/jwtToken=(.*)$/.exec(document.cookie) && !!document.cookie.split(";").find(it => !!/jwtToken=(.*)$/.exec(it))?.split("=")?.[1]
  useEffect(() => {
    (async () => {
      // 更改titie
      const link = document.querySelector('link[rel="icon"]') as any
      link.href = SYSTEM_LOGO
      document.title = `${SYSTEM_NAME}`
      // 每次将flag设置为default，只有当请求接口token失效时会拿到这个值做登录判断
      await dispatch(actions.global.setState({ tokenFailure: false }))
      const cookieHostname = getCookie('cookieHostname')
      if (areUrlsEnvironmentNonConflicting(window.location.hostname, cookieHostname) && hasToken) {
        handle()
      }
    })()
  }, [])

  const [loading, handle] = useLoadingHandle(async () => {
    await $.fetch['POST/crm-stars/v1/statistics/catUserClubDetail']({ userId: 0 })
      .then(async ([data]) => {
        //     await dispatch(actions.global.setButtonList(data?.buttonList))
        //     await dispatch(actions.global.setIsRoot(data?.isRoot))
        //     const menuListCode = extractCodes(data.menuList || [])
        //     await dispatch(actions.global.setMenuCodeList(menuListCode))
        //     // 有些系统不需要scrm的info 要查询uaa的info 比如mms营销系统
        //     // 在这里不做if else判断 如果某个系统不使用scrm组织树 info等 就在各自系统自己查询uaa
        //     // if (!MONO_CONFIG?.notNeedScrm) {
        //     //   await dispatch(actions.global.queryStaff())
        //     // }
        //     if (!menuListCode?.length && !data?.isRoot) {
        //       await $.msg.warning(`你还不是${BUSINESS}系统人员,请联系管理员添加哦`, 2)
        //       return
        //     }
        // $isLogin(true)
      })
      .catch(async (e) => {
        //     console.log(e);
        //     if (e.code == "21110007") {
        //       $.msg.error(e.message)
        //     }
        // $isLogin(false)
        if (e?.message === 'token已失效,请重新登陆') {
          message.error('用户需要登录')
          setCookie('jwtToken', '', {
            domain: '.starfilled.cn',
            expires: 1
          })
          dispatch(actions.storage.removeItem('userInfo'))
        }
      })
  })

  const signOut = () => {
    // form.current?.submit()
    window.location.reload()
    setCookie('jwtToken', '', {
      domain: '.starfilled.cn',
      expires: 1
    })
    dispatch(actions.storage.removeItem('userInfo'))
  }

  return (
    <AuthContext.Provider value={{ isLogin, signOut }}>
      <Visible visible={loading}>
        <Skeleton />
      </Visible>
      <Visible visible={!loading}>
        {hasToken ?
          <>
            {children}
            <form
              ref={form}
              encType="application/json;chartset=UTF-8"
              action={`${API_PREFIX}ums/internal/employee/logout`}
              method="get"
              style={{ display: 'none' }}
            >
              <input name="business" value={BUSINESS} type="hidden" />
              <input name="goUrl" value={`${window.location.origin}/login`} type="hidden" />
              <button type="submit">退出登录</button>
            </form>
          </>
          :
          <DefaultLoginPage bgUrl={bgUrl} logo={logo} />
        }
      </Visible>
    </AuthContext.Provider>
  )
}

export default Auth
