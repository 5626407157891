/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-23 09:56:34
 * @Description:
 */
import { Avatar, Popover, Button } from 'antd'
import { useContext, useState } from 'react'
import { css, cx } from '@emotion/css'
import { CaretDownOutlined } from '@ant-design/icons'
import { useSelector } from '@/store'
import { AuthContext } from '@/core/auth/context'

const UserSetting = () => {
  // const { token } = useToken()
  // const { name, avatarUrl } = useSelector((state) => state.global.user)
  const userInfo = useSelector(
    (state) => state.storage.userInfo)
  const [open, setOpen] = useState(false)
  const { signOut } = useContext(AuthContext)

  return (
    <Popover
      content={
        <Button
          type='link'
          onClick={() => {
            signOut?.()
          }}
        >
          退出登录
        </Button>
      }
      placement='bottom'
      showArrow={false}
      trigger='click'
      open={open}
      onOpenChange={(value) => setOpen(value)}
    >
      <Button className={'flex items-center mr-2 px-1 '} style={{ backgroundColor: '#FFFFFF' }} type='text'>
        <div className={cx('ml-2', 'text-slate-300', css({ marginInline: 8, color: 'rgb(140, 140, 140)' }))}>{userInfo?.userName}</div>
        <CaretDownOutlined />
      </Button>
    </Popover>
  )
}

export default UserSetting
