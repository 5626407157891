import { Button, Layout } from 'antd'
// import Icon from '@ant-design/icons'

// import { actions, useAppDispatch, useSelector } from '@/store'
import Icon from '@ant-design/icons'
import Sun from '@/assets/icons_svg/sun.svg'
import Moon from '@/assets/icons_svg/moon.svg'
import UserSetting from './UserSetting'
import ThemeSetting from './ThemeSetting'
import AppEntry from './AppEntry'
import { actions, useAppDispatch, useSelector } from '@/store'
import Breadcrumb from '../Breadcrumb'

const { Header: AntHeader } = Layout

const Header = () => {
  const { dark } = useSelector(state => state.global.settings)
  const dispatch = useAppDispatch()

  const handleChangeThemeMode = () => {
    dispatch(actions.global.setSettings({ dark: !dark }))
  }
  return (
    <AntHeader
      className={'w-full overflow-hidden'}
      // style={{ padding: '0 24px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.12)', background: 'transparent ', height: 55 }}
      style={{ padding: '0 24px', background: 'transparent ', height: 90 }}
    >
      <div className={'w-full h-full flex items-center justify-between'}>
        {/* <AppEntry /> */}
        <div style={{ lineHeight: 55 }}>
          <Breadcrumb />
        </div>
        <div className={'flex-1 flex items-center justify-end '}>
          {/* <ThemeSetting /> */}
          {/* <Button
            style={{ background: 'transparent', border: 'none' }}
            onClick={handleChangeThemeMode}
            icon={<Icon component={dark ? Sun : Moon} style={{ fontSize: 18 }} />}
          /> */}
          <UserSetting />
        </div>
      </div>
    </AntHeader>
  )
}

export default Header
