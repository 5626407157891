import classNames from "classnames"
import { useEffect, useState, useImperativeHandle, forwardRef } from "react"
import type { CSSProperties } from "react"

// import styles from "./index.module.scss";

type Ref = {
  validate: (vcode: string) => void;
};

interface VerifyCodeProps {
  /** 验证码长度 */
  size?: number;
  /** 默认canvas宽度 */
  width?: number;
  /** 默认canvas高度 */
  height?: number;
  /** 图形验证码默认类型 blend: 数字字母混合类型 number:纯数字 letter:纯字母 */
  type?: "blend" | "number" | "letter";
  /** 自定义类名 */
  className?: string;
  /** 自定义样式 */
  style?: CSSProperties;
  /** 图形验证码id */
  id?: string;
  /** canvasid */
  canvasId?: string;
}

/** 生成字母数组 */
const getAllLetter = () => {
  const letterStr = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z"
  return letterStr.split(",")
}

/** 生成一个随机数 */
const randomNum = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min)
}

/** 生成一个随机色 */
const randomColor = (min: number, max: number) => {
  let r = randomNum(min, max)
  let g = randomNum(min, max)
  let b = randomNum(min, max)
  return `rgb(${  r  },${  g  },${  b  })`
}

/** 数字数组 */
const numArr = "0,1,2,3,4,5,6,7,8,9".split(",")
/** 字母数组 */
const letterArr = getAllLetter()

/** 图文验证码 */
const VerifyCode = forwardRef<Ref, VerifyCodeProps>((props, ref) => {
  const { size = 4, width = 100, height = 40, type: _pType = "number", style, className, id, canvasId } = props
  const [options, setOptions] = useState<{ id: string; canvasId: string; code: string }>({
    id: id || "verifycode", // 容器Id
    canvasId: canvasId || "verifyCanvas", // canvas的ID
    code: "",
  })

  useImperativeHandle(ref, () => ({
    /** 方便外面组件验证是否正确 */
    validate: (vcode) => {
      let codeState: boolean = false
      let v_code = options.code.toLowerCase()
      if (vcode.toLowerCase() == v_code) {
        codeState = true
      } else {
        /** 输入校验的信息存在，但是输入有错，那么刷新下验证码 */
        vcode && refresh()
        codeState = false
      }
      return codeState
    },
  }))

  useEffect(() => {
    _init()
    refresh()
  }, [size, width, height])

  /** 初始化 */
  const _init = () => {
    let con = document.getElementById(options.id) as HTMLElement
    let canvas = document.createElement("canvas") as HTMLCanvasElement
    canvas.id = options.canvasId
    canvas.width = con.offsetWidth || width
    canvas.height = con.offsetHeight || height
    canvas.style.cursor = "pointer"
    canvas.innerHTML = "您的浏览器版本不支持canvas"
    con.appendChild(canvas)
    canvas.onclick = function () {
      refresh()
    }
  }

  /** 生成验证码 */
  const refresh = () => {
    options.code = ""
    let canvas: any = document.getElementById(options.canvasId)
    let ctx = null
    let txtArr = []
    if (!canvas.getContext) {return}
    ctx = canvas.getContext("2d")
    /** 清空一个矩形 */
    ctx.clearRect(0, 0, width, height)
    ctx.textBaseline = "middle"

    ctx.fillStyle = randomColor(180, 240)
    ctx.fillStyle = "rgba(0,0,0,0)"
    ctx.fillRect(0, 0, width, height)

    if (_pType == "blend") {
      // 判断验证码类型
      txtArr = numArr.concat(letterArr)
    } else if (_pType == "number") {
      txtArr = numArr
    } else {
      txtArr = letterArr
    }

    for (let i = 1; i <= size; i++) {
      let txt = txtArr[randomNum(0, txtArr.length)]
      options.code += txt
      ctx.font = `${randomNum(height / 2, height)  }px SimHei` // 随机生成字体大小
      ctx.fillStyle = randomColor(50, 160) // 随机生成字体颜色
      // ctx.fillStyle = "rgb(46, 137, 255)" // 固定字体颜色
      ctx.shadowOffsetX = randomNum(-3, 3)
      ctx.shadowOffsetY = randomNum(-3, 3)
      ctx.shadowBlur = randomNum(-3, 3)
      ctx.shadowColor = "rgba(0, 0, 0, 0.3)"
      let x = (width / (size + 1)) * i
      let y = height / 2
      let deg = randomNum(-30, 30)
      /** 设置旋转角度和坐标原点* */
      ctx.translate(x, y)
      ctx.rotate((deg * Math.PI) / 180)
      ctx.fillText(txt, 0, 0)
      /** 恢复旋转角度和坐标原点* */
      ctx.rotate((-deg * Math.PI) / 180)
      ctx.translate(-x, -y)
    }
    /** 绘制干扰线 */
    for (let i = 0; i < 4; i++) {
      ctx.strokeStyle = randomColor(40, 180)
      ctx.beginPath()
      ctx.moveTo(randomNum(0, width), randomNum(0, height))
      ctx.lineTo(randomNum(0, width), randomNum(0, height))
      ctx.stroke()
    }
  }

  return <div id={id || "verifycode"} style={style} className={classNames(className)} />
})

export default VerifyCode
