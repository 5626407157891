/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-11-03 17:35:03
 * @Description: 
 */
import { Layout, theme, Skeleton, ConfigProvider } from 'antd'
import { css, cx } from '@emotion/css'

// @ts-ignore
import { useSelector } from "@/store"
// @ts-ignore 反向引用
import { SYSTEM_NAME, SYSTEM_LOGO } from '@/core/config'

import { AliasToken } from 'antd/es/theme/internal'

const { Sider: AntSider, Header: AntHeader, Content } = Layout
const { useToken } = theme

const renderStyles = (args: { token: Partial<AliasToken>; dark: boolean }) => {
  const { token, dark } = args
  return {
    sider: cx(
      'h-full',
      css({
        boxShadow: '0px 1px 4px 0px rgba(0, 21, 41, 0.12)',
        '.ant-layout-sider-children': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 0
        }
      })
    ),
    siderLogo: cx(
      'w-full flex items-center overflow-hidden',
      css({ height: 65, paddingBlock: 8, paddingInline: 16 })
    ),
    siderContent: cx('flex flex-col item-center justify-center', css({ marginLeft: 12 })),
    siderLogoText: css({
      fontSize: '14px',
      color: '#fff',
      lineHeight: '18px',
      fontWeight: 500
      // color: dark ? token.colorText : token.colorPrimaryActive
    }),
    company: css({
      fontSize: 10,
      color: 'rgba(22, 119, 255, 1)'
    }),
    siderIcon: css({ width: '32px', height: '32px' }),
    menu: cx(
      'flex-1 overflow-y-auto',
      css({
        '.ant-menu-light .ant-menu-item-selected': {
          background: 'rgba(0, 21, 41, 1)'
        },
        '.ant-menu-dark .ant-menu-item-selected': {
          color: token.colorWhite,
          // background: 'red'
        },
        '.ant-menu-dark .ant-menu-submenu-title, .ant-menu-dark ant-menu-title-content': {
          color: token.colorWhite,
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 48,
          height: 48
        },

        '.ant-menu-sub.ant-menu-inline': {
          background: '#000d17 !important',
        },
        '.ant-menu-submenu-selected > .ant-menu-submenu-title': {
          '> .ant-menu-title-content,> .ant-menu-inline-collapsed-noicon': {
            fontWeight: 500
          }
        },
        '.ant-menu-item-selected': {
          '.ant-menu-title-content,.ant-menu-inline-collapsed-noicon': {
            fontWeight: 500
          }
        }
      })
    )
  }
}

const App: React.FC = () => {
  const { token } = useToken()
  const { dark } = useSelector((state: { global: { settings: any; }; }) => state.global.settings)
  const styles = renderStyles({ token, dark })

  const renderLogo = () => (
    <>
      <div className={styles.siderLogo}>
        <img src={SYSTEM_LOGO} className={styles.siderIcon} />
        <div className={styles.siderContent}>
          <div className={styles.siderLogoText}>{SYSTEM_NAME}</div>
          <div className={styles.company}></div>
        </div>
      </div>
    </>
  )
  return (
    <Layout className={'h-full flex flex-row '}>
      <AntSider
        className={styles.sider}
        theme="dark"
        width={256}
        collapsedWidth="72"
        breakpoint="lg"
        collapsed={false}
      >
        {renderLogo()}
        <ConfigProvider
          theme={{
            components: {
              Skeleton: {
                gradientFromColor: 'rgba(200,200,200,.5)',
                titleHeight: 25,
                paragraphLiHeight: 20
              }
            }
          }}
        >
          <div className='pl-3 pr-3 mt-5'>
            <Skeleton active />
            <Skeleton active className='mt-10' />
          </div>
        </ConfigProvider>
      </AntSider>
      <Layout>
        <AntHeader
          className={cx(
            'w-full overflow-hidden',
            css({
              padding: '0 24px',
              boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.12)',
              height: 48,
            })
          )}
        >

        </AntHeader>
        <Content
          id="main"
          className={
            cx(
              'flex-1 relative flex h-full flex-col overflow-auto',
              css({ padding: '20px', borderRadius: '8px 8px 0 0' })
            )
          }
        >
          <ConfigProvider
            theme={{
              components: {
                Skeleton: {
                  titleHeight: 25,
                  paragraphLiHeight: 25
                }
              }
            }}
          >
            <Skeleton active />
            <Skeleton active className='mt-5' />
          </ConfigProvider>
        </Content>
      </Layout>

    </Layout>
  )
}

export default App
