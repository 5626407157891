import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import useRouter from '@manage/core/router/useRouter'
import { setCookie } from '@manage/core/tools/storage'
import renderStyles from './css'
import VerifyCode from './VerifyCode'
import { actions, dispatch } from '@/store'
import { validateStartPhone } from '@/pages/utils'

type Props = {
  bgUrl: string,
  logo: string,
}

const App: React.FC = (props: Props) => {
  const { bgUrl, logo } = props
  const styles = renderStyles()
  const [countdown, setCountdown] = useState(60)
  useEffect(() => {
    // 每次路由改变时，收集新的页面视图事件
    window.collectEvent('predefinePageView', {
      url: `${window.location.origin  }/login`,
      url_path: window.location.pathname,
    })
  }, [])
  const [isCountingDown, setIsCountingDown] = useState(false)
  const [form] = Form.useForm()
  const VerifyCodeRef = useRef<any>()
  const backVerificationCodeRef = useRef<any>('')
  const [onclickCode, setOnclickCode] = useState(true)
  const [onInputFocusStatus, setOnInputFocusStatus] = useState(true)

  const router = useRouter()

  // const pictureCode = Form.useWatch('pictureCode', form)
  const [pictureCode, setPictureCode] = useState(false)
  const loginName = Form.useWatch('loginName', form)
  const password = Form.useWatch('password', form)

  // 账号登录还是验证码登录
  const [isAccount, setIsAccount] = useState(false)

  const onChangeClick = (e: any) => {
    setIsAccount(e === 1)
    form.resetFields()
  }

  const onclickLogin = async () => {
    const res = await form.validateFields()
    // 账号
    if (isAccount) {
      const [token] = await $.fetch['POST/crm-stars/v1/sysUser/login']({ grantType: 'password', loginName: res.loginName, password: res.password })
      setCookie('cookieHostname', window.location.hostname, {
        domain: '.starfilled.cn',
        expires: 1
      })
      setCookie('jwtToken', token?.token, {
        domain: '.starfilled.cn',
        expires: 1
      })
      const [data] = await $.fetch['POST/crm-stars/v1/phone/getById']({ userId: token?.user?.userId })
      await dispatch(actions.storage.setItem({ key: 'userInfo', value: { ...token?.user, dataEnc: data?.dataEnc } }))
      await dispatch(actions.globalModel.setIsLogin(true))
      router.replace('/manageCockpit/clueManagement/clueEntry')
    } else if (!isAccount) {
      // 验证码
      const codeState = VerifyCodeRef.current?.validate(res?.pictureCode)
      if (codeState) {
        const [token] = await $.fetch['POST/crm-stars/v1/sysUser/login']({ grantType: 'sms_code', phoneNumber: res.phone, smsCode: res.verifyCode, verifyToken: backVerificationCodeRef.current })
        setCookie('cookieHostname', window.location.hostname, {
          domain: '.starfilled.cn',
          expires: 1
        })
        setCookie('jwtToken', token?.token, {
          domain: '.starfilled.cn',
          expires: 1
        })
        const [data] = await $.fetch['POST/crm-stars/v1/phone/getById']({ userId: token?.user?.userId })
        await dispatch(actions.storage.setItem({ key: 'userInfo', value: { ...token?.user, dataEnc: data?.dataEnc } }))
        await dispatch(actions.globalModel.setIsLogin(true))
        // window.location.href = `/manageCockpit/clueManagement/clueEntry`
        router.replace('/manageCockpit/clueManagement/clueEntry')
      } else {
        message.error('图片校验失败')
      }
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(prevCountdown => prevCountdown - 1)
      } else {
        setIsCountingDown(false)
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [countdown, isCountingDown])

  const handleClick = async () => {
    if (!isCountingDown) {
      const phone = await form.validateFields(['phone'])
      // 发送验证码请求到后端
      const [verifyToken] = await $.fetch['POST/crm-stars/v1/sms/send'](phone)
      backVerificationCodeRef.current = verifyToken?.verifyToken
      sendVerificationCodeRequest()
      setIsCountingDown(true)
      setOnclickCode(false)
    }
  }

  const sendVerificationCodeRequest = () => {
    // 模拟发送请求
    console.log('验证码发送成功')
    // 设置倒计时
    setCountdown(60)
  }

  const onInputFocus = () => {
    setOnInputFocusStatus(true)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  const validatorPhone = (e: any) => {
    if (!e?.target?.value) {
      return false
    }
    if (validateStartPhone(e?.target?.value)) {
      return false
    }
    if (!(/^1[3-9]\d{9}$/.test(e?.target?.value))) {
      return false
    }
    return true
  }
  return (
    <div className={styles.page} style={{ backgroundImage: `url(${bgUrl})` }}>
      <div className={styles.leftPage}>
        <div className={styles.titleLogo}>
          <div className={styles.logo}>
            <img src={logo} style={{ width: '100%', height: '100%' }}></img>
          </div>
          <div className={styles.title}>繁星系统</div>
        </div>
      </div>
      <div className={styles.rightPage}>
        <div className={styles.card}>
          <div className={styles.change} >
            <div style={{ color: !isAccount && 'rgba(24, 130, 255, 1)' }} onClick={() => { onChangeClick(0) }}>验证码登录</div>
            <div className={styles.titleIn}></div>
            <div style={{ color: isAccount && 'rgba(24, 130, 255, 1)' }} onClick={() => { onChangeClick(1) }}>账号登录</div>
          </div>

          <Form
            layout='vertical'
            form={form}
            name="basic"
            size={'large'}
            style={{ width: '100%' }}
            autoComplete="off"
            onFinishFailed={onFinishFailed}
          >
            {isAccount &&
              <>
                <Form.Item
                  label="用户名"
                  name="loginName"
                  rules={[{ required: true, message: '请输入用户名' }]}
                >

                  <Input className={styles.loginInput} />
                </Form.Item>

                <Form.Item
                  label="密码"
                  name="password"
                  rules={[{ required: true, message: '请输入密码' }]}
                >
                  <Input.Password className={styles.loginInput} />
                </Form.Item>
              </>
            }
            {!isAccount &&
              <>
                <Form.Item
                  label="手机号"
                  name="phone"
                  validateTrigger='onBlur'
                  validateStatus={onInputFocusStatus ? 'success' : 'error'} // 使用一个状态变量来控制校验状态
                  help={onInputFocusStatus ? '' : '请输入有效的手机号码'} // 当isValid为true时，帮助信息应为null或不显示
                  rules={[
                    {
                      required: true, message: '',
                    },
                  ]}
                >

                  <Input
                    onFocus={onInputFocus}
                    onBlur={(e) => setOnInputFocusStatus(validatorPhone(e))}
                    className={styles.loginInput}
                    maxLength={11}
                  />
                </Form.Item>

                <Form.Item
                  label="图片验证码"
                  required={true}
                >
                  <div className='flex justify-between w-[100%] gap-[10px]'>
                    <Form.Item
                      noStyle
                      name="pictureCode"
                      rules={[{
                        required: true,
                        // message: '请输入图形验证码',
                        validator: async (_, value) => {
                          if (value.length === 4) {
                            if (VerifyCodeRef.current?.validate(value)) {
                              setPictureCode(true)
                              return Promise.resolve()
                            }
                            setPictureCode(false)
                            return Promise.reject(new Error('图片校验失败'))
                          }
                          setPictureCode(false)
                        },
                      }]}>
                      <Input className={styles.loginInput} />
                    </Form.Item>
                    <VerifyCode ref={VerifyCodeRef}></VerifyCode>
                  </div>
                </Form.Item>

                <Form.Item
                  label="验证码"
                  required={true}
                >
                  <div className='flex justify-between w-[100%] gap-[10px]'>
                    <Form.Item
                      noStyle
                      name="verifyCode"
                      rules={[{
                        required: true,
                        message: '请输入验证码',
                      }]}
                    >
                      <Input className={styles.loginInput} />
                    </Form.Item>
                    <Button type='primary' onClick={handleClick} disabled={isCountingDown || !pictureCode}>  {isCountingDown ? `重新发送(${countdown})` : '获取验证码'}</Button>
                  </div>
                </Form.Item>
              </>
            }
            <Button
              disabled={isAccount ? (!loginName || !password) : onclickCode}
              className={styles.footerButton}
              type='primary'
              onClick={() => onclickLogin()}
            >
              登录
            </Button>
          </Form>
        </div>
      </div >
      <a href='https://beian.miit.gov.cn/' target="_blank" className={styles.recordNumber} rel="noreferrer">蜀ICP备2024085419号-1</a>
    </div >

  )
}
export default App
